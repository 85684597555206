import { default as _91_46_46_46not_45found_9395AqTmjjtMMeta } from "C:/FE02/_work/254/s/Gallery/pages/[...not-found].vue?macro=true";
import { default as aboutK4339kDQQRMeta } from "C:/FE02/_work/254/s/Gallery/pages/about.vue?macro=true";
import { default as account_45createdi0yOkyPyFNMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/account-created.vue?macro=true";
import { default as additional_45informationMtZ3TOeWkzMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/additional-information.vue?macro=true";
import { default as addressesHyFauxCcykMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/addresses.vue?macro=true";
import { default as amendmentebM9UwlYSNMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/amendment.vue?macro=true";
import { default as indexCeE8yGrlYCMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/bank-account/index.vue?macro=true";
import { default as requestedDzjdMM5eAdMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/bank-account/requested.vue?macro=true";
import { default as contract_45signing7YVRCYJCphMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/contract-signing.vue?macro=true";
import { default as document_45updateRBarnHTjTPMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/document-update.vue?macro=true";
import { default as documents_45checkvEYqx2haHiMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/documents-check.vue?macro=true";
import { default as indexWg3jb2zItFMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/identity-verification/index.vue?macro=true";
import { default as requestednNClHAXSRYMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/identity-verification/requested.vue?macro=true";
import { default as upload_45documentsEQ7CCER44SMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/identity-verification/upload-documents.vue?macro=true";
import { default as personal_45data1mRaQXL075Meta } from "C:/FE02/_work/254/s/Gallery/pages/account/personal-data.vue?macro=true";
import { default as phone_45number_45verificationxP2qKNnUQxMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/phone-number-verification.vue?macro=true";
import { default as index9damAMtoLFMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/politically-exposed-person/index.vue?macro=true";
import { default as requestednZH5NbspgOMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/politically-exposed-person/requested.vue?macro=true";
import { default as questionnaireewi7XqFBn5Meta } from "C:/FE02/_work/254/s/Gallery/pages/account/questionnaire.vue?macro=true";
import { default as indexhMW2CQ1gziMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/source-of-income-statement/index.vue?macro=true";
import { default as requestedoewXA56gBkMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/source-of-income-statement/requested.vue?macro=true";
import { default as verificationY2OrTVAcJjMeta } from "C:/FE02/_work/254/s/Gallery/pages/account/verification.vue?macro=true";
import { default as annual_45reportswmSpNxXUQNMeta } from "C:/FE02/_work/254/s/Gallery/pages/annual-reports.vue?macro=true";
import { default as bankidKWp39RlJCmMeta } from "C:/FE02/_work/254/s/Gallery/pages/bankid.vue?macro=true";
import { default as contacteOanjZN4lUMeta } from "C:/FE02/_work/254/s/Gallery/pages/contact.vue?macro=true";
import { default as contractual_45documentationwvRsRx2Am7Meta } from "C:/FE02/_work/254/s/Gallery/pages/contractual-documentation.vue?macro=true";
import { default as _91id_93MIbAkkgQ4rMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/[id].vue?macro=true";
import { default as _91id_93nciwstAhgeMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue?macro=true";
import { default as newCIZHhGqHPNMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue?macro=true";
import { default as indexs2tAEoeMq7Meta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/index.vue?macro=true";
import { default as my_45advertisementsy26kY6ESp9Meta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue?macro=true";
import { default as termspiq6SrGAWIMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/terms.vue?macro=true";
import { default as _91id_93LhyfRdT0V0Meta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue?macro=true";
import { default as indexB3Ohs8H7LwMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/trades/index.vue?macro=true";
import { default as newWYUAk5Fu8OMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/trades/new.vue?macro=true";
import { default as advertising_45space1Aw6OJuUIXMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space.vue?macro=true";
import { default as depositf5nJokwAJiMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/deposit.vue?macro=true";
import { default as indexGPzuFMgkPvMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/index.vue?macro=true";
import { default as purchase_45instructions0upLS0FM1iMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/purchase-instructions.vue?macro=true";
import { default as transactions_45historybFFhLN4vNVMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/transactions-history.vue?macro=true";
import { default as confirmationJIGyYl3fPSMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue?macro=true";
import { default as indexVuS9aZoRFMMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/withdrawal/index.vue?macro=true";
import { default as financeelLuue2cpAMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/finance.vue?macro=true";
import { default as indexzdX6GJSTItMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue?macro=true";
import { default as unique_45exhibition_45alfons_45muchaZH1eptIh9qMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue?macro=true";
import { default as vouchers3yqsmSl5qJMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue?macro=true";
import { default as gifts_45and_45benefitsL401kEJXJYMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits.vue?macro=true";
import { default as indexiHZqzmNlgVMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/index.vue?macro=true";
import { default as indexuAUg112YKoMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/index.vue?macro=true";
import { default as _91slug_93wOZerLIZ57Meta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue?macro=true";
import { default as indexcqk0gyRCnUMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/investments-offer/index.vue?macro=true";
import { default as investments_45offerZ6fAHOMxxkMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/investments-offer.vue?macro=true";
import { default as my_45investmentsuPmmvZpkBSMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/my-investments.vue?macro=true";
import { default as votingxPihHhRtYqMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/voting.vue?macro=true";
import { default as investmentsqElLAAPWFGMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/investments.vue?macro=true";
import { default as add_45accountAxwf4U2ny8Meta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/add-account.vue?macro=true";
import { default as additional_45settingsYtxLe3QwzZMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/additional-settings.vue?macro=true";
import { default as index1UgwDUrQ0BMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue?macro=true";
import { default as requestedsIY2HkNFUTMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue?macro=true";
import { default as upload_45documentQoUDdOw94UMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue?macro=true";
import { default as indexpgCofoWtWTMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue?macro=true";
import { default as delete_45accountJtp4zGIVgAMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/delete-account.vue?macro=true";
import { default as indexNuWmZYFLqlMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/document-update/index.vue?macro=true";
import { default as requestedg6HnJUDXyrMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/document-update/requested.vue?macro=true";
import { default as edit_45personal_45informationZrzszygxaPMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/edit-personal-information.vue?macro=true";
import { default as email_45changeLbBeG8ShNBMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/email-change.vue?macro=true";
import { default as email_45preferencesF2ISD4P3oCMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/email-preferences.vue?macro=true";
import { default as czech_45point72ItMFMfECMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue?macro=true";
import { default as indexNKUrvTvZ7xMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/identity-verification/index.vue?macro=true";
import { default as indexTN29TnQvqQMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/index.vue?macro=true";
import { default as mailing_45address_45changeLTUAM3X4dsMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/mailing-address-change.vue?macro=true";
import { default as index5kvKk3AZVDMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/name-change/index.vue?macro=true";
import { default as requestedPLIqXdDiWnMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/name-change/requested.vue?macro=true";
import { default as upload_45documentszvi6yxyg5VMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue?macro=true";
import { default as indexk6gg8QpQlTMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue?macro=true";
import { default as requestedXIA94nxCpNMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue?macro=true";
import { default as upload_45documentsx9LO2jBdwAMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue?macro=true";
import { default as additional_45informationGDdd2Nd1tcMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue?macro=true";
import { default as indexOH8hlCCeUXMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue?macro=true";
import { default as indexOjmODptQzsMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue?macro=true";
import { default as requestedhUVo4j15LnMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue?macro=true";
import { default as indexC2p7O8PTvWMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue?macro=true";
import { default as requestedI5EKsbFpZYMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue?macro=true";
import { default as indextj74nwutNkMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue?macro=true";
import { default as requestedgVCmDBbHpCMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue?macro=true";
import { default as personal_45data_45confirmationld5xuTWTAQMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue?macro=true";
import { default as phone_45changeCmGQ9Kmu7bMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/phone-change.vue?macro=true";
import { default as index3yTwqSQquUMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/security/index.vue?macro=true";
import { default as password_45changeUVKNElhGSSMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/security/password-change.vue?macro=true";
import { default as documentseXsBmEqLV8Meta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue?macro=true";
import { default as indexii3iwcLT9LMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue?macro=true";
import { default as statements_45and_45confirmationsjaeYR6VfRCMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue?macro=true";
import { default as profileK73JRG34ytMeta } from "C:/FE02/_work/254/s/Gallery/pages/dashboard/profile.vue?macro=true";
import { default as elementk3QgYppOvQMeta } from "C:/FE02/_work/254/s/Gallery/pages/element.vue?macro=true";
import { default as email_45verification0pMA1DcGkXMeta } from "C:/FE02/_work/254/s/Gallery/pages/external/email-verification.vue?macro=true";
import { default as impersonation0KYkl6oiTDMeta } from "C:/FE02/_work/254/s/Gallery/pages/external/impersonation.vue?macro=true";
import { default as loginG8MzNj7DOuMeta } from "C:/FE02/_work/254/s/Gallery/pages/external/login.vue?macro=true";
import { default as password_45resetTUCS5UmjW9Meta } from "C:/FE02/_work/254/s/Gallery/pages/external/password-reset.vue?macro=true";
import { default as _91slug_93j70eNIOKh0Meta } from "C:/FE02/_work/254/s/Gallery/pages/faq/[slug].vue?macro=true";
import { default as indexMms3GSqaEgMeta } from "C:/FE02/_work/254/s/Gallery/pages/faq/index.vue?macro=true";
import { default as faq5F8kyTrDDpMeta } from "C:/FE02/_work/254/s/Gallery/pages/faq.vue?macro=true";
import { default as forgotten_45passwordXkBm3gRY8DMeta } from "C:/FE02/_work/254/s/Gallery/pages/forgotten-password.vue?macro=true";
import { default as how_45does_45it_45workBQ2ZEQQr0AMeta } from "C:/FE02/_work/254/s/Gallery/pages/how-does-it-work.vue?macro=true";
import { default as indexrbIvBTGq5QMeta } from "C:/FE02/_work/254/s/Gallery/pages/index.vue?macro=true";
import { default as _91slug_93d6T6jLRnAyMeta } from "C:/FE02/_work/254/s/Gallery/pages/investments-offer/[slug].vue?macro=true";
import { default as index5PG6TU7b4iMeta } from "C:/FE02/_work/254/s/Gallery/pages/investments-offer/index.vue?macro=true";
import { default as login3yxsoZlUB6Meta } from "C:/FE02/_work/254/s/Gallery/pages/login.vue?macro=true";
import { default as obligatory_45published_45informationjYyf2dvqlqMeta } from "C:/FE02/_work/254/s/Gallery/pages/obligatory-published-information.vue?macro=true";
import { default as privacy_45settingsRYgsEubEspMeta } from "C:/FE02/_work/254/s/Gallery/pages/privacy-settings.vue?macro=true";
import { default as registrationCZiNLltQc7Meta } from "C:/FE02/_work/254/s/Gallery/pages/registration.vue?macro=true";
import { default as securityU4hHfGncEwMeta } from "C:/FE02/_work/254/s/Gallery/pages/security.vue?macro=true";
export default [
  {
    name: "notfound___cs___default",
    path: "/:notfound(.*)*",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/[...not-found].vue").then(m => m.default || m)
  },
  {
    name: "notfound___cs",
    path: "/cs/:notfound(.*)*",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/[...not-found].vue").then(m => m.default || m)
  },
  {
    name: "about___cs___default",
    path: "/o-nas",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___cs",
    path: "/cs/o-nas",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "account-account-created___cs___default",
    path: "/ucet/ucet-vytvoren",
    meta: account_45createdi0yOkyPyFNMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/account-created.vue").then(m => m.default || m)
  },
  {
    name: "account-account-created___cs",
    path: "/cs/ucet/ucet-vytvoren",
    meta: account_45createdi0yOkyPyFNMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/account-created.vue").then(m => m.default || m)
  },
  {
    name: "account-additional-information___cs___default",
    path: "/ucet/dalsi-informace",
    meta: additional_45informationMtZ3TOeWkzMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "account-additional-information___cs",
    path: "/cs/ucet/dalsi-informace",
    meta: additional_45informationMtZ3TOeWkzMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___cs___default",
    path: "/ucet/adresy",
    meta: addressesHyFauxCcykMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___cs",
    path: "/cs/ucet/adresy",
    meta: addressesHyFauxCcykMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-amendment___cs___default",
    path: "/ucet/dodatek",
    meta: amendmentebM9UwlYSNMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/amendment.vue").then(m => m.default || m)
  },
  {
    name: "account-amendment___cs",
    path: "/cs/ucet/dodatek",
    meta: amendmentebM9UwlYSNMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/amendment.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account___cs___default",
    path: "/ucet/bankovni-ucet",
    meta: indexCeE8yGrlYCMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/bank-account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account___cs",
    path: "/cs/ucet/bankovni-ucet",
    meta: indexCeE8yGrlYCMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/bank-account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account-requested___cs___default",
    path: "/ucet/bankovni-ucet/overeni",
    meta: requestedDzjdMM5eAdMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/bank-account/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account-requested___cs",
    path: "/cs/ucet/bankovni-ucet/overeni",
    meta: requestedDzjdMM5eAdMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/bank-account/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-contract-signing___cs___default",
    path: "/ucet/podpis-smlouvy",
    meta: contract_45signing7YVRCYJCphMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/contract-signing.vue").then(m => m.default || m)
  },
  {
    name: "account-contract-signing___cs",
    path: "/cs/ucet/podpis-smlouvy",
    meta: contract_45signing7YVRCYJCphMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/contract-signing.vue").then(m => m.default || m)
  },
  {
    name: "account-document-update___cs___default",
    path: "/ucet/aktualizace-dokladu",
    meta: document_45updateRBarnHTjTPMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/document-update.vue").then(m => m.default || m)
  },
  {
    name: "account-document-update___cs",
    path: "/cs/ucet/aktualizace-dokladu",
    meta: document_45updateRBarnHTjTPMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/document-update.vue").then(m => m.default || m)
  },
  {
    name: "account-documents-check___cs___default",
    path: "/ucet/kontrola-dokladu",
    meta: documents_45checkvEYqx2haHiMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/documents-check.vue").then(m => m.default || m)
  },
  {
    name: "account-documents-check___cs",
    path: "/cs/ucet/kontrola-dokladu",
    meta: documents_45checkvEYqx2haHiMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/documents-check.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification___cs___default",
    path: "/ucet/overeni-identity",
    meta: indexWg3jb2zItFMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification___cs",
    path: "/cs/ucet/overeni-identity",
    meta: indexWg3jb2zItFMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-requested___cs___default",
    path: "/ucet/overeni-identity/overeni",
    meta: requestednNClHAXSRYMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/identity-verification/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-requested___cs",
    path: "/cs/ucet/overeni-identity/overeni",
    meta: requestednNClHAXSRYMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/identity-verification/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-upload-documents___cs___default",
    path: "/ucet/overeni-identity/nahrat-doklady",
    meta: upload_45documentsEQ7CCER44SMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/identity-verification/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-upload-documents___cs",
    path: "/cs/ucet/overeni-identity/nahrat-doklady",
    meta: upload_45documentsEQ7CCER44SMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/identity-verification/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-data___cs___default",
    path: "/ucet/osobni-udaje",
    meta: personal_45data1mRaQXL075Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/personal-data.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-data___cs",
    path: "/cs/ucet/osobni-udaje",
    meta: personal_45data1mRaQXL075Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/personal-data.vue").then(m => m.default || m)
  },
  {
    name: "account-phone-number-verification___cs___default",
    path: "/ucet/overeni-telefonniho-cisla",
    meta: phone_45number_45verificationxP2qKNnUQxMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/phone-number-verification.vue").then(m => m.default || m)
  },
  {
    name: "account-phone-number-verification___cs",
    path: "/cs/ucet/overeni-telefonniho-cisla",
    meta: phone_45number_45verificationxP2qKNnUQxMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/phone-number-verification.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person___cs___default",
    path: "/ucet/politicky-exponovana-osoba",
    meta: index9damAMtoLFMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person___cs",
    path: "/cs/ucet/politicky-exponovana-osoba",
    meta: index9damAMtoLFMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person-requested___cs___default",
    path: "/ucet/politicky-exponovana-osoba/overeni",
    meta: requestednZH5NbspgOMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person-requested___cs",
    path: "/cs/ucet/politicky-exponovana-osoba/overeni",
    meta: requestednZH5NbspgOMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-questionnaire___cs___default",
    path: "/ucet/dotaznik",
    meta: questionnaireewi7XqFBn5Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: "account-questionnaire___cs",
    path: "/cs/ucet/dotaznik",
    meta: questionnaireewi7XqFBn5Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement___cs___default",
    path: "/ucet/potvrzeni-zdroje-prijmu",
    meta: indexhMW2CQ1gziMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement___cs",
    path: "/cs/ucet/potvrzeni-zdroje-prijmu",
    meta: indexhMW2CQ1gziMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement-requested___cs___default",
    path: "/ucet/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedoewXA56gBkMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/source-of-income-statement/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement-requested___cs",
    path: "/cs/ucet/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedoewXA56gBkMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/source-of-income-statement/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-verification___cs___default",
    path: "/ucet/verifikace",
    meta: verificationY2OrTVAcJjMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/verification.vue").then(m => m.default || m)
  },
  {
    name: "account-verification___cs",
    path: "/cs/ucet/verifikace",
    meta: verificationY2OrTVAcJjMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/account/verification.vue").then(m => m.default || m)
  },
  {
    name: "annual-reports___cs___default",
    path: "/vyrocni-zpravy",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/annual-reports.vue").then(m => m.default || m)
  },
  {
    name: "annual-reports___cs",
    path: "/cs/vyrocni-zpravy",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/annual-reports.vue").then(m => m.default || m)
  },
  {
    name: "bankid___cs___default",
    path: "/bankid",
    meta: bankidKWp39RlJCmMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/bankid.vue").then(m => m.default || m)
  },
  {
    name: "bankid___cs",
    path: "/cs/bankid",
    meta: bankidKWp39RlJCmMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/bankid.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs___default",
    path: "/kontakt",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs",
    path: "/cs/kontakt",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contractual-documentation___cs___default",
    path: "/smluvni-dokumentace",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/contractual-documentation.vue").then(m => m.default || m)
  },
  {
    name: "contractual-documentation___cs",
    path: "/cs/smluvni-dokumentace",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/contractual-documentation.vue").then(m => m.default || m)
  },
  {
    name: advertising_45space1Aw6OJuUIXMeta?.name,
    path: "/souhrn/inzertni-plocha",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-advertising-space-id___cs___default",
    path: ":id()",
    meta: _91id_93MIbAkkgQ4rMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-id___cs___default",
    path: "inzerat/:id()",
    meta: _91id_93nciwstAhgeMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-new___cs___default",
    path: "inzerat/novy",
    meta: newCIZHhGqHPNMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space___cs___default",
    path: "/souhrn/inzertni-plocha",
    meta: indexs2tAEoeMq7Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-my-advertisements___cs___default",
    path: "moje-inzeraty",
    meta: my_45advertisementsy26kY6ESp9Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-terms___cs___default",
    path: "podminky",
    meta: termspiq6SrGAWIMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/terms.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-id___cs___default",
    path: "pokyny-k-prevodu/:id()",
    meta: _91id_93LhyfRdT0V0Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades___cs___default",
    path: "pokyny-k-prevodu",
    meta: indexB3Ohs8H7LwMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/trades/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-new___cs___default",
    path: "pokyny-k-prevodu/novy",
    meta: newWYUAk5Fu8OMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/trades/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: advertising_45space1Aw6OJuUIXMeta?.name,
    path: "/cs/souhrn/inzertni-plocha",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-advertising-space-id___cs",
    path: ":id()",
    meta: _91id_93MIbAkkgQ4rMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-id___cs",
    path: "inzerat/:id()",
    meta: _91id_93nciwstAhgeMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-new___cs",
    path: "inzerat/novy",
    meta: newCIZHhGqHPNMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space___cs",
    path: "/cs/souhrn/inzertni-plocha",
    meta: indexs2tAEoeMq7Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-my-advertisements___cs",
    path: "moje-inzeraty",
    meta: my_45advertisementsy26kY6ESp9Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-terms___cs",
    path: "podminky",
    meta: termspiq6SrGAWIMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/terms.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-id___cs",
    path: "pokyny-k-prevodu/:id()",
    meta: _91id_93LhyfRdT0V0Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades___cs",
    path: "pokyny-k-prevodu",
    meta: indexB3Ohs8H7LwMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/trades/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-new___cs",
    path: "pokyny-k-prevodu/novy",
    meta: newWYUAk5Fu8OMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/advertising-space/trades/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: financeelLuue2cpAMeta?.name,
    path: "/souhrn/penize",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-finance-deposit___cs___default",
    path: "vklad",
    meta: depositf5nJokwAJiMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/deposit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance___cs___default",
    path: "/souhrn/penize",
    meta: indexGPzuFMgkPvMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-purchase-instructions___cs___default",
    path: "pokyny",
    meta: purchase_45instructions0upLS0FM1iMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/purchase-instructions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-transactions-history___cs___default",
    path: "transakcni-historie",
    meta: transactions_45historybFFhLN4vNVMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/transactions-history.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal-confirmation___cs___default",
    path: "vyber/potvrzeni",
    meta: confirmationJIGyYl3fPSMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal___cs___default",
    path: "vyber",
    meta: indexVuS9aZoRFMMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/withdrawal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: financeelLuue2cpAMeta?.name,
    path: "/cs/souhrn/penize",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-finance-deposit___cs",
    path: "vklad",
    meta: depositf5nJokwAJiMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/deposit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance___cs",
    path: "/cs/souhrn/penize",
    meta: indexGPzuFMgkPvMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-purchase-instructions___cs",
    path: "pokyny",
    meta: purchase_45instructions0upLS0FM1iMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/purchase-instructions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-transactions-history___cs",
    path: "transakcni-historie",
    meta: transactions_45historybFFhLN4vNVMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/transactions-history.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal-confirmation___cs",
    path: "vyber/potvrzeni",
    meta: confirmationJIGyYl3fPSMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal___cs",
    path: "vyber",
    meta: indexVuS9aZoRFMMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/finance/withdrawal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gifts_45and_45benefitsL401kEJXJYMeta?.name,
    path: "/souhrn/darky-a-vyhody",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-gifts-and-benefits___cs___default",
    path: "",
    meta: indexzdX6GJSTItMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-unique-exhibition-alfons-mucha___cs___default",
    path: "unikatni-vystava-alfons-mucha",
    meta: unique_45exhibition_45alfons_45muchaZH1eptIh9qMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-vouchers___cs___default",
    path: "darkove-poukazy",
    meta: vouchers3yqsmSl5qJMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gifts_45and_45benefitsL401kEJXJYMeta?.name,
    path: "/cs/souhrn/darky-a-vyhody",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-gifts-and-benefits___cs",
    path: "",
    meta: indexzdX6GJSTItMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-unique-exhibition-alfons-mucha___cs",
    path: "unikatni-vystava-alfons-mucha",
    meta: unique_45exhibition_45alfons_45muchaZH1eptIh9qMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-vouchers___cs",
    path: "darkove-poukazy",
    meta: vouchers3yqsmSl5qJMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard___cs___default",
    path: "/souhrn",
    meta: indexiHZqzmNlgVMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___cs",
    path: "/cs/souhrn",
    meta: indexiHZqzmNlgVMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: investmentsqElLAAPWFGMeta?.name,
    path: "/souhrn/investice",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments___cs___default",
    path: "",
    meta: indexuAUg112YKoMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/index.vue").then(m => m.default || m)
  },
  {
    name: investments_45offerZ6fAHOMxxkMeta?.name,
    path: "nabidka-investic",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/investments-offer.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments-investments-offer-slug___cs___default",
    path: "/souhrn/investice/:slug()",
    meta: _91slug_93wOZerLIZ57Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-investments-offer___cs___default",
    path: "/souhrn/investice/nabidka-investic",
    meta: indexcqk0gyRCnUMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/investments-offer/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-investments-my-investments___cs___default",
    path: "moje-investice",
    meta: my_45investmentsuPmmvZpkBSMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/my-investments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-voting___cs___default",
    path: "hlasovani",
    meta: votingxPihHhRtYqMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: investmentsqElLAAPWFGMeta?.name,
    path: "/cs/souhrn/investice",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments___cs",
    path: "",
    meta: indexuAUg112YKoMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/index.vue").then(m => m.default || m)
  },
  {
    name: investments_45offerZ6fAHOMxxkMeta?.name,
    path: "nabidka-investic",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/investments-offer.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments-investments-offer-slug___cs",
    path: "/cs/souhrn/investice/:slug()",
    meta: _91slug_93wOZerLIZ57Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-investments-offer___cs",
    path: "/cs/souhrn/investice/nabidka-investic",
    meta: indexcqk0gyRCnUMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/investments-offer/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-investments-my-investments___cs",
    path: "moje-investice",
    meta: my_45investmentsuPmmvZpkBSMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/my-investments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-voting___cs",
    path: "hlasovani",
    meta: votingxPihHhRtYqMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/investments/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileK73JRG34ytMeta?.name,
    path: "/dashboard/profile",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-add-account___cs___default",
    path: "/souhrn/profil/pridat-ucet",
    meta: add_45accountAxwf4U2ny8Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/add-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-additional-settings___cs___default",
    path: "/souhrn/profil/dalsi-nastaveni",
    meta: additional_45settingsYtxLe3QwzZMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/additional-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat",
    meta: index1UgwDUrQ0BMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-requested___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat/overeni",
    meta: requestedsIY2HkNFUTMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-upload-document___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat/nahrat-doklad",
    meta: upload_45documentQoUDdOw94UMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts___cs___default",
    path: "/souhrn/profil/bankovni-ucty",
    meta: indexpgCofoWtWTMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-delete-account___cs___default",
    path: "/souhrn/profil/zruseni-uctu",
    meta: delete_45accountJtp4zGIVgAMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update___cs___default",
    path: "/souhrn/profil/aktualizace-dokladu",
    meta: indexNuWmZYFLqlMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/document-update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update-requested___cs___default",
    path: "/souhrn/profil/aktualizace-dokladu/overeni",
    meta: requestedg6HnJUDXyrMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/document-update/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-edit-personal-information___cs___default",
    path: "/souhrn/profil/uprava-osobnich-udaju",
    meta: edit_45personal_45informationZrzszygxaPMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/edit-personal-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-change___cs___default",
    path: "/souhrn/profil/zmena-e-mailu",
    meta: email_45changeLbBeG8ShNBMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/email-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-preferences___cs___default",
    path: "/souhrn/profil/e-mailova-sdeleni",
    meta: email_45preferencesF2ISD4P3oCMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/email-preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification-czech-point___cs___default",
    path: "/souhrn/profil/overeni-totoznosti/czech-point",
    meta: czech_45point72ItMFMfECMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification___cs___default",
    path: "/souhrn/profil/overeni-totoznosti",
    meta: indexNKUrvTvZ7xMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___cs___default",
    path: "/souhrn/profil",
    meta: indexTN29TnQvqQMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-mailing-address-change___cs___default",
    path: "/souhrn/profil/zmena-korespondencni-adresy",
    meta: mailing_45address_45changeLTUAM3X4dsMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/mailing-address-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change___cs___default",
    path: "/souhrn/profil/zmena-jmena",
    meta: index5kvKk3AZVDMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/name-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-requested___cs___default",
    path: "/souhrn/profil/zmena-jmena/overeni",
    meta: requestedPLIqXdDiWnMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/name-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-upload-documents___cs___default",
    path: "/souhrn/profil/zmena-jmena/nahrat-doklady",
    meta: upload_45documentszvi6yxyg5VMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy",
    meta: indexk6gg8QpQlTMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-requested___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy/overeni",
    meta: requestedXIA94nxCpNMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-upload-documents___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy/nahrat-doklady",
    meta: upload_45documentsx9LO2jBdwAMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: personal_45data_45confirmationld5xuTWTAQMeta?.name,
    path: "personal-data-confirmation",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-personal-data-confirmation-additional-information___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/dalsi-informace",
    meta: additional_45informationGDdd2Nd1tcMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation___cs___default",
    path: "/souhrn/profil/aktualizace-udaju",
    meta: indexOH8hlCCeUXMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/novy-dokument",
    meta: indexOjmODptQzsMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/novy-dokument/overeni",
    meta: requestedhUVo4j15LnMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba",
    meta: indexC2p7O8PTvWMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba/overeni",
    meta: requestedI5EKsbFpZYMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu",
    meta: indextj74nwutNkMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedgVCmDBbHpCMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-phone-change___cs___default",
    path: "/souhrn/profil/zmena-telefonu",
    meta: phone_45changeCmGQ9Kmu7bMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/phone-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security___cs___default",
    path: "/souhrn/profil/bezpecnost",
    meta: index3yTwqSQquUMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/security/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security-password-change___cs___default",
    path: "/souhrn/profil/bezpecnost/zmena-hesla",
    meta: password_45changeUVKNElhGSSMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/security/password-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-documents___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty/dokumenty",
    meta: documentseXsBmEqLV8Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty",
    meta: indexii3iwcLT9LMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-statements-and-confirmations___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty/vypisy-a-potvrzeni",
    meta: statements_45and_45confirmationsjaeYR6VfRCMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileK73JRG34ytMeta?.name,
    path: "/cs/dashboard/profile",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-add-account___cs",
    path: "/cs/souhrn/profil/pridat-ucet",
    meta: add_45accountAxwf4U2ny8Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/add-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-additional-settings___cs",
    path: "/cs/souhrn/profil/dalsi-nastaveni",
    meta: additional_45settingsYtxLe3QwzZMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/additional-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat",
    meta: index1UgwDUrQ0BMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-requested___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat/overeni",
    meta: requestedsIY2HkNFUTMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-upload-document___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat/nahrat-doklad",
    meta: upload_45documentQoUDdOw94UMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts___cs",
    path: "/cs/souhrn/profil/bankovni-ucty",
    meta: indexpgCofoWtWTMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-delete-account___cs",
    path: "/cs/souhrn/profil/zruseni-uctu",
    meta: delete_45accountJtp4zGIVgAMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update___cs",
    path: "/cs/souhrn/profil/aktualizace-dokladu",
    meta: indexNuWmZYFLqlMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/document-update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-dokladu/overeni",
    meta: requestedg6HnJUDXyrMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/document-update/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-edit-personal-information___cs",
    path: "/cs/souhrn/profil/uprava-osobnich-udaju",
    meta: edit_45personal_45informationZrzszygxaPMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/edit-personal-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-change___cs",
    path: "/cs/souhrn/profil/zmena-e-mailu",
    meta: email_45changeLbBeG8ShNBMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/email-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-preferences___cs",
    path: "/cs/souhrn/profil/e-mailova-sdeleni",
    meta: email_45preferencesF2ISD4P3oCMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/email-preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification-czech-point___cs",
    path: "/cs/souhrn/profil/overeni-totoznosti/czech-point",
    meta: czech_45point72ItMFMfECMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification___cs",
    path: "/cs/souhrn/profil/overeni-totoznosti",
    meta: indexNKUrvTvZ7xMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___cs",
    path: "/cs/souhrn/profil",
    meta: indexTN29TnQvqQMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-mailing-address-change___cs",
    path: "/cs/souhrn/profil/zmena-korespondencni-adresy",
    meta: mailing_45address_45changeLTUAM3X4dsMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/mailing-address-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change___cs",
    path: "/cs/souhrn/profil/zmena-jmena",
    meta: index5kvKk3AZVDMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/name-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-requested___cs",
    path: "/cs/souhrn/profil/zmena-jmena/overeni",
    meta: requestedPLIqXdDiWnMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/name-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-upload-documents___cs",
    path: "/cs/souhrn/profil/zmena-jmena/nahrat-doklady",
    meta: upload_45documentszvi6yxyg5VMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy",
    meta: indexk6gg8QpQlTMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-requested___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy/overeni",
    meta: requestedXIA94nxCpNMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-upload-documents___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy/nahrat-doklady",
    meta: upload_45documentsx9LO2jBdwAMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: personal_45data_45confirmationld5xuTWTAQMeta?.name,
    path: "personal-data-confirmation",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-personal-data-confirmation-additional-information___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/dalsi-informace",
    meta: additional_45informationGDdd2Nd1tcMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju",
    meta: indexOH8hlCCeUXMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/novy-dokument",
    meta: indexOjmODptQzsMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/novy-dokument/overeni",
    meta: requestedhUVo4j15LnMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba",
    meta: indexC2p7O8PTvWMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba/overeni",
    meta: requestedI5EKsbFpZYMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu",
    meta: indextj74nwutNkMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedgVCmDBbHpCMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-phone-change___cs",
    path: "/cs/souhrn/profil/zmena-telefonu",
    meta: phone_45changeCmGQ9Kmu7bMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/phone-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security___cs",
    path: "/cs/souhrn/profil/bezpecnost",
    meta: index3yTwqSQquUMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/security/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security-password-change___cs",
    path: "/cs/souhrn/profil/bezpecnost/zmena-hesla",
    meta: password_45changeUVKNElhGSSMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/security/password-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-documents___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty/dokumenty",
    meta: documentseXsBmEqLV8Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty",
    meta: indexii3iwcLT9LMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-statements-and-confirmations___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty/vypisy-a-potvrzeni",
    meta: statements_45and_45confirmationsjaeYR6VfRCMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "element___cs___default",
    path: "/element",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/element.vue").then(m => m.default || m)
  },
  {
    name: "element___cs",
    path: "/cs/element",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/element.vue").then(m => m.default || m)
  },
  {
    name: "external-email-verification___cs___default",
    path: "/external/email-verification",
    meta: email_45verification0pMA1DcGkXMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/external/email-verification.vue").then(m => m.default || m)
  },
  {
    name: "external-email-verification___cs",
    path: "/cs/external/email-verification",
    meta: email_45verification0pMA1DcGkXMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/external/email-verification.vue").then(m => m.default || m)
  },
  {
    name: "external-impersonation___cs___default",
    path: "/external/impersonation",
    meta: impersonation0KYkl6oiTDMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/external/impersonation.vue").then(m => m.default || m)
  },
  {
    name: "external-impersonation___cs",
    path: "/cs/external/impersonation",
    meta: impersonation0KYkl6oiTDMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/external/impersonation.vue").then(m => m.default || m)
  },
  {
    name: "external-login___cs___default",
    path: "/external/login",
    meta: loginG8MzNj7DOuMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/external/login.vue").then(m => m.default || m)
  },
  {
    name: "external-login___cs",
    path: "/cs/external/login",
    meta: loginG8MzNj7DOuMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/external/login.vue").then(m => m.default || m)
  },
  {
    name: "external-password-reset___cs___default",
    path: "/external/password-reset",
    meta: password_45resetTUCS5UmjW9Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/external/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "external-password-reset___cs",
    path: "/cs/external/password-reset",
    meta: password_45resetTUCS5UmjW9Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/external/password-reset.vue").then(m => m.default || m)
  },
  {
    name: faq5F8kyTrDDpMeta?.name,
    path: "/faq",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/faq.vue").then(m => m.default || m),
    children: [
  {
    name: "faq-slug___cs___default",
    path: ":slug()",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq___cs___default",
    path: "",
    meta: indexMms3GSqaEgMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/faq/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: faq5F8kyTrDDpMeta?.name,
    path: "/cs/faq",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/faq.vue").then(m => m.default || m),
    children: [
  {
    name: "faq-slug___cs",
    path: ":slug()",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq___cs",
    path: "",
    meta: indexMms3GSqaEgMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/faq/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forgotten-password___cs___default",
    path: "/zapomenute-heslo",
    meta: forgotten_45passwordXkBm3gRY8DMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: "forgotten-password___cs",
    path: "/cs/zapomenute-heslo",
    meta: forgotten_45passwordXkBm3gRY8DMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: "how-does-it-work___cs___default",
    path: "/jak-to-funguje",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/how-does-it-work.vue").then(m => m.default || m)
  },
  {
    name: "how-does-it-work___cs",
    path: "/cs/jak-to-funguje",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/how-does-it-work.vue").then(m => m.default || m)
  },
  {
    name: "index___cs___default",
    path: "/",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "investments-offer-slug___cs___default",
    path: "/nabidka-investic/:slug()",
    meta: _91slug_93d6T6jLRnAyMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "investments-offer-slug___cs",
    path: "/cs/nabidka-investic/:slug()",
    meta: _91slug_93d6T6jLRnAyMeta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "investments-offer___cs___default",
    path: "/nabidka-investic",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/investments-offer/index.vue").then(m => m.default || m)
  },
  {
    name: "investments-offer___cs",
    path: "/cs/nabidka-investic",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/investments-offer/index.vue").then(m => m.default || m)
  },
  {
    name: "login___cs___default",
    path: "/prihlasit-se",
    meta: login3yxsoZlUB6Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___cs",
    path: "/cs/prihlasit-se",
    meta: login3yxsoZlUB6Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "obligatory-published-information___cs___default",
    path: "/povinne-informace",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/obligatory-published-information.vue").then(m => m.default || m)
  },
  {
    name: "obligatory-published-information___cs",
    path: "/cs/povinne-informace",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/obligatory-published-information.vue").then(m => m.default || m)
  },
  {
    name: "privacy-settings___cs___default",
    path: "/nastaveni-soukromi",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/privacy-settings.vue").then(m => m.default || m)
  },
  {
    name: "privacy-settings___cs",
    path: "/cs/nastaveni-soukromi",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/privacy-settings.vue").then(m => m.default || m)
  },
  {
    name: "registration___cs___default",
    path: "/registrace",
    meta: registrationCZiNLltQc7Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration___cs",
    path: "/cs/registrace",
    meta: registrationCZiNLltQc7Meta || {},
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "security___cs___default",
    path: "/bezpecnost",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "security___cs",
    path: "/cs/bezpecnost",
    component: () => import("C:/FE02/_work/254/s/Gallery/pages/security.vue").then(m => m.default || m)
  }
]