import revive_payload_client_4sVQNw7RlN from "C:/FE02/_work/254/s/Gallery/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/FE02/_work/254/s/Gallery/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/FE02/_work/254/s/Gallery/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/FE02/_work/254/s/Gallery/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/FE02/_work/254/s/Gallery/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/FE02/_work/254/s/Gallery/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "C:/FE02/_work/254/s/Gallery/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "C:/FE02/_work/254/s/Gallery/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/FE02/_work/254/s/Gallery/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "C:/FE02/_work/254/s/Gallery/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "C:/FE02/_work/254/s/Gallery/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import analytics_tracking_5CmEexImkh from "C:/FE02/_work/254/s/Gallery/plugins/analytics-tracking.ts";
import date_fns_frLEpKX25w from "C:/FE02/_work/254/s/Gallery/plugins/date-fns.ts";
import gtm_cXsUZobmSF from "C:/FE02/_work/254/s/Gallery/plugins/gtm.ts";
import i18n_VfGcjrvSkj from "C:/FE02/_work/254/s/Gallery/plugins/i18n.ts";
import identity_number_validation_client_My8wbgNspA from "C:/FE02/_work/254/s/Gallery/plugins/identity-number-validation.client.ts";
import idle_VeSbcnEWUf from "C:/FE02/_work/254/s/Gallery/plugins/idle.ts";
import scrollbar_width_nZYNYxuX5k from "C:/FE02/_work/254/s/Gallery/plugins/scrollbar-width.ts";
import slugify_AVRv38arlk from "C:/FE02/_work/254/s/Gallery/plugins/slugify.ts";
import v_calendar_dxaVzst4iS from "C:/FE02/_work/254/s/Gallery/plugins/v-calendar.ts";
import vue_lightbox_LTUjowbopi from "C:/FE02/_work/254/s/Gallery/plugins/vue-lightbox.ts";
import vue_silentbox_uCPfmnKzmg from "C:/FE02/_work/254/s/Gallery/plugins/vue-silentbox.ts";
import vue_tippy_YMxDLgIIQU from "C:/FE02/_work/254/s/Gallery/plugins/vue-tippy.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  analytics_tracking_5CmEexImkh,
  date_fns_frLEpKX25w,
  gtm_cXsUZobmSF,
  i18n_VfGcjrvSkj,
  identity_number_validation_client_My8wbgNspA,
  idle_VeSbcnEWUf,
  scrollbar_width_nZYNYxuX5k,
  slugify_AVRv38arlk,
  v_calendar_dxaVzst4iS,
  vue_lightbox_LTUjowbopi,
  vue_silentbox_uCPfmnKzmg,
  vue_tippy_YMxDLgIIQU
]