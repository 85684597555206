
// @ts-nocheck
import locale_C_58_C_58_FE02__work_254_s_Gallery_locales_cs_json from "../locales/cs.json";


export const localeCodes =  [
  "cs"
]

export const localeLoaders = {
  "cs": [{ key: "../locales/cs.json", load: () => Promise.resolve(locale_C_58_C_58_FE02__work_254_s_Gallery_locales_cs_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../locales/i18n.config.ts?hash=91504c8a&config=1" /* webpackChunkName: "__locales_i18n_config_ts_91504c8a" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./locales/i18n.config.ts",
  "locales": [
    {
      "code": "cs",
      "language": "cs-CZ",
      "isCatchallLocale": true,
      "files": [
        "C:/FE02/_work/254/s/Gallery/locales/cs.json"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n",
    "cookieSecure": true,
    "fallbackLocale": "cs",
    "redirectOn": "all",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://gallery.portu.cz",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "account/documents-check": {
      "cs": "/ucet/kontrola-dokladu",
      "en": "/account/documents-check"
    },
    "account/personal-data": {
      "cs": "/ucet/osobni-udaje",
      "en": "/account/personal-data"
    },
    "account/phone-number-verification": {
      "cs": "/ucet/overeni-telefonniho-cisla",
      "en": "/account/phone-number-verification"
    },
    "account/politically-exposed-person/index": {
      "cs": "/ucet/politicky-exponovana-osoba",
      "en": "/account/politically-exposed-person"
    },
    "account/politically-exposed-person/requested": {
      "cs": "/ucet/politicky-exponovana-osoba/overeni",
      "en": "/account/politically-exposed-person/requested"
    },
    "account/source-of-income-statement/index": {
      "cs": "/ucet/potvrzeni-zdroje-prijmu",
      "en": "/account/source-of-income-statement"
    },
    "account/source-of-income-statement/requested": {
      "cs": "/ucet/potvrzeni-zdroje-prijmu/overeni",
      "en": "/account/source-of-income-statement/requested"
    },
    "account/identity-verification/index": {
      "cs": "/ucet/overeni-identity",
      "en": "/account/identity-verification"
    },
    "account/identity-verification/requested": {
      "cs": "/ucet/overeni-identity/overeni",
      "en": "/account/identity-verification/requested"
    },
    "account/identity-verification/upload-documents": {
      "cs": "/ucet/overeni-identity/nahrat-doklady",
      "en": "/account/identity-verification/upload-documents"
    },
    "account/bank-account/index": {
      "cs": "/ucet/bankovni-ucet",
      "en": "/account/bank-account"
    },
    "account/bank-account/requested": {
      "cs": "/ucet/bankovni-ucet/overeni",
      "en": "/account/bank-account/requested"
    },
    "account/additional-information": {
      "cs": "/ucet/dalsi-informace",
      "en": "/account/additional-information"
    },
    "account/addresses": {
      "cs": "/ucet/adresy",
      "en": "/account/addresses"
    },
    "account/amendment": {
      "cs": "/ucet/dodatek",
      "en": "/account/amendment"
    },
    "account/contract-signing": {
      "cs": "/ucet/podpis-smlouvy",
      "en": "/account/contract-signing"
    },
    "account/document-update": {
      "cs": "/ucet/aktualizace-dokladu",
      "en": "/account/document-update"
    },
    "account/questionnaire": {
      "cs": "/ucet/dotaznik",
      "en": "/account/questionnaire"
    },
    "account/verification": {
      "cs": "/ucet/verifikace",
      "en": "/account/verification"
    },
    "account/account-created": {
      "cs": "/ucet/ucet-vytvoren",
      "en": "/account/account-created"
    },
    "dashboard/index": {
      "cs": "/souhrn",
      "en": "/dashboard"
    },
    "dashboard/advertising-space": {
      "cs": "/souhrn/inzertni-plocha",
      "en": "/dashboard/advertising-space"
    },
    "dashboard/advertising-space/index": {
      "cs": "/souhrn/inzertni-plocha",
      "en": "/dashboard/advertising-space"
    },
    "dashboard/advertising-space/advertisement/[id]": {
      "cs": "/souhrn/inzertni-plocha/inzerat/[id]",
      "en": "/dashboard/advertising-space/advertisement/[id]"
    },
    "dashboard/advertising-space/advertisement/new": {
      "cs": "/souhrn/inzertni-plocha/inzerat/novy",
      "en": "/dashboard/advertising-space/advertisement/novy"
    },
    "dashboard/advertising-space/[id]": {
      "cs": "/souhrn/inzertni-plocha/[id]",
      "en": "/dashboard/advertising-space/[id]"
    },
    "dashboard/advertising-space/my-advertisements": {
      "cs": "/souhrn/inzertni-plocha/moje-inzeraty",
      "en": "/dashboard/advertising-space/my-advertisements"
    },
    "dashboard/advertising-space/terms": {
      "cs": "/souhrn/inzertni-plocha/podminky",
      "en": "/dashboard/advertising-space/terms"
    },
    "dashboard/advertising-space/trades/index": {
      "cs": "/souhrn/inzertni-plocha/pokyny-k-prevodu",
      "en": "/dashboard/advertising-space/trades"
    },
    "dashboard/advertising-space/trades/[id]": {
      "cs": "/souhrn/inzertni-plocha/pokyny-k-prevodu/[id]",
      "en": "/dashboard/advertising-space/trades/[id]"
    },
    "dashboard/advertising-space/trades/new": {
      "cs": "/souhrn/inzertni-plocha/pokyny-k-prevodu/novy",
      "en": "/dashboard/advertising-space/trades/new"
    },
    "dashboard/finance": {
      "cs": "/souhrn/penize",
      "en": "/dashboard/finance"
    },
    "dashboard/finance/index": {
      "cs": "/souhrn/penize",
      "en": "/dashboard/finance"
    },
    "dashboard/finance/deposit": {
      "cs": "/souhrn/penize/vklad",
      "en": "/dashboard/finance/deposit"
    },
    "dashboard/finance/purchase-instructions": {
      "cs": "/souhrn/penize/pokyny",
      "en": "/dashboard/finance/purchase-instructions"
    },
    "dashboard/finance/transactions-history": {
      "cs": "/souhrn/penize/transakcni-historie",
      "en": "/dashboard/finance/transactions-history"
    },
    "dashboard/finance/withdrawal/index": {
      "cs": "/souhrn/penize/vyber",
      "en": "/dashboard/finance/withdrawal"
    },
    "dashboard/finance/withdrawal/confirmation": {
      "cs": "/souhrn/penize/vyber/potvrzeni",
      "en": "/dashboard/finance/withdrawal/confirmation"
    },
    "dashboard/gifts-and-benefits": {
      "cs": "/souhrn/darky-a-vyhody",
      "en": "/dashboard/gifts-and-benefits"
    },
    "dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha": {
      "cs": "/souhrn/darky-a-vyhody/unikatni-vystava-alfons-mucha",
      "en": "/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha"
    },
    "dashboard/gifts-and-benefits/vouchers": {
      "cs": "/souhrn/darky-a-vyhody/darkove-poukazy",
      "en": "/dashboard/gifts-and-benefits/vouchers"
    },
    "dashboard/profile/index": {
      "cs": "/souhrn/profil",
      "en": "/dashboard/profile"
    },
    "dashboard/profile/bank-accounts/index": {
      "cs": "/souhrn/profil/bankovni-ucty",
      "en": "/dashboard/profile/bank-accounts"
    },
    "dashboard/profile/bank-accounts/add/index": {
      "cs": "/souhrn/profil/bankovni-ucty/pridat",
      "en": "/dashboard/profile/bank-accounts/add"
    },
    "dashboard/profile/bank-accounts/add/requested": {
      "cs": "/souhrn/profil/bankovni-ucty/pridat/overeni",
      "en": "/dashboard/profile/bank-accounts/add/requested"
    },
    "dashboard/profile/bank-accounts/add/upload-document": {
      "cs": "/souhrn/profil/bankovni-ucty/pridat/nahrat-doklad",
      "en": "/dashboard/profile/bank-accounts/add/upload-document"
    },
    "dashboard/profile/document-update/index": {
      "cs": "/souhrn/profil/aktualizace-dokladu",
      "en": "/dashboard/profile/document-update"
    },
    "dashboard/profile/document-update/requested": {
      "cs": "/souhrn/profil/aktualizace-dokladu/overeni",
      "en": "/dashboard/profile/document-update/requested"
    },
    "dashboard/profile/identity-verification/index": {
      "cs": "/souhrn/profil/overeni-totoznosti",
      "en": "/dashboard/profile/identity-verification"
    },
    "dashboard/profile/identity-verification/czech-point": {
      "cs": "/souhrn/profil/overeni-totoznosti/czech-point",
      "en": "/dashboard/profile/identity-verification/czech-point"
    },
    "dashboard/profile/add-account": {
      "cs": "/souhrn/profil/pridat-ucet",
      "en": "/dashboard/profile/add-account"
    },
    "dashboard/profile/additional-settings": {
      "cs": "/souhrn/profil/dalsi-nastaveni",
      "en": "/dashboard/profile/additional-settings"
    },
    "dashboard/profile/delete-account": {
      "cs": "/souhrn/profil/zruseni-uctu",
      "en": "/dashboard/profile/delete-account"
    },
    "dashboard/profile/mailing-address-change": {
      "cs": "/souhrn/profil/zmena-korespondencni-adresy",
      "en": "/dashboard/profile/mailing-address-change"
    },
    "dashboard/profile/edit-personal-information": {
      "cs": "/souhrn/profil/uprava-osobnich-udaju",
      "en": "/dashboard/profile/edit-personal-information"
    },
    "dashboard/profile/email-change": {
      "cs": "/souhrn/profil/zmena-e-mailu",
      "en": "/dashboard/profile/email-change"
    },
    "dashboard/profile/email-preferences": {
      "cs": "/souhrn/profil/e-mailova-sdeleni",
      "en": "/dashboard/profile/email-preferences"
    },
    "dashboard/profile/name-change/index": {
      "cs": "/souhrn/profil/zmena-jmena",
      "en": "/dashboard/profile/name-change"
    },
    "dashboard/profile/name-change/requested": {
      "cs": "/souhrn/profil/zmena-jmena/overeni",
      "en": "/dashboard/profile/name-change/requested"
    },
    "dashboard/profile/name-change/upload-documents": {
      "cs": "/souhrn/profil/zmena-jmena/nahrat-doklady",
      "en": "/dashboard/profile/name-change/upload-documents"
    },
    "dashboard/profile/permanent-address-change/index": {
      "cs": "/souhrn/profil/zmena-travale-adresy",
      "en": "/dashboard/profile/permanent-address-change"
    },
    "dashboard/profile/permanent-address-change/requested": {
      "cs": "/souhrn/profil/zmena-travale-adresy/overeni",
      "en": "/dashboard/profile/permanent-address-change/requested"
    },
    "dashboard/profile/permanent-address-change/upload-documents": {
      "cs": "/souhrn/profil/zmena-travale-adresy/nahrat-doklady",
      "en": "/dashboard/profile/permanent-address-change/upload-documents"
    },
    "dashboard/profile/personal-data-confirmation/index": {
      "cs": "/souhrn/profil/aktualizace-udaju",
      "en": "/dashboard/profile/personal-data-confirmation"
    },
    "dashboard/profile/personal-data-confirmation/additional-information": {
      "cs": "/souhrn/profil/aktualizace-udaju/dalsi-informace",
      "en": "/dashboard/profile/personal-data-confirmation/additional-information"
    },
    "dashboard/profile/personal-data-confirmation/new-document/index": {
      "cs": "/souhrn/profil/aktualizace-udaju/novy-dokument",
      "en": "/dashboard/profile/personal-data-confirmation/new-document"
    },
    "dashboard/profile/personal-data-confirmation/new-document/requested": {
      "cs": "/souhrn/profil/aktualizace-udaju/novy-dokument/overeni",
      "en": "/dashboard/profile/personal-data-confirmation/new-document/requested"
    },
    "dashboard/profile/personal-data-confirmation/politically-exposed-person/index": {
      "cs": "/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba",
      "en": "/dashboard/profile/personal-data-confirmation/politically-exposed-person"
    },
    "dashboard/profile/personal-data-confirmation/politically-exposed-person/requested": {
      "cs": "/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba/overeni",
      "en": "/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested"
    },
    "dashboard/profile/personal-data-confirmation/source-of-income-statement/index": {
      "cs": "/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu",
      "en": "/dashboard/profile/personal-data-confirmation/source-of-income-statement"
    },
    "dashboard/profile/personal-data-confirmation/source-of-income-statement/requested": {
      "cs": "/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu/overeni",
      "en": "/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested"
    },
    "dashboard/profile/phone-change": {
      "cs": "/souhrn/profil/zmena-telefonu",
      "en": "/dashboard/profile/phone-change"
    },
    "dashboard/profile/security/index": {
      "cs": "/souhrn/profil/bezpecnost",
      "en": "/dashboard/profile/security"
    },
    "dashboard/profile/security/password-change": {
      "cs": "/souhrn/profil/bezpecnost/zmena-hesla",
      "en": "/dashboard/profile/security/password-change"
    },
    "dashboard/profile/statements-and-documents/index": {
      "cs": "/souhrn/profil/vypisy-a-dokumenty",
      "en": "/dashboard/profile/statements-and-documents"
    },
    "dashboard/profile/statements-and-documents/documents": {
      "cs": "/souhrn/profil/vypisy-a-dokumenty/dokumenty",
      "en": "/dashboard/profile/statements-and-documents/documents"
    },
    "dashboard/profile/statements-and-documents/statements-and-confirmations": {
      "cs": "/souhrn/profil/vypisy-a-dokumenty/vypisy-a-potvrzeni",
      "en": "/dashboard/profile/statements-and-documents/statements-and-confirmations"
    },
    "dashboard/investments": {
      "cs": "/souhrn/investice",
      "en": "/dashboard/investments"
    },
    "dashboard/investments/investments-offer": {
      "cs": "/souhrn/investice/nabidka-investic",
      "en": "/dashboard/investments/investments-offer"
    },
    "dashboard/investments/investments-offer/index": {
      "cs": "/souhrn/investice/nabidka-investic",
      "en": "/dashboard/investments/investments-offer"
    },
    "dashboard/investments/investments-offer/[slug]": {
      "cs": "/souhrn/investice/nabidka-investic/[slug]",
      "en": "/dashboard/investments/investments-offer/[slug]"
    },
    "dashboard/investments/my-investments": {
      "cs": "/souhrn/investice/moje-investice",
      "en": "/dashboard/investments/my-investments"
    },
    "dashboard/investments/voting": {
      "cs": "/souhrn/investice/hlasovani",
      "en": "/dashboard/investments/voting"
    },
    "external/email-verification": {
      "cs": "/external/email-verification",
      "en": "/external/email-verification"
    },
    "external/login": {
      "cs": "/external/login",
      "en": "/external/login"
    },
    "external/password-reset": {
      "cs": "/external/password-reset",
      "en": "/external/password-reset"
    },
    "faq": {
      "cs": "/faq",
      "en": "/faq"
    },
    "faq/[slug]": {
      "cs": "/faq/[slug]",
      "en": "/faq/[slug]"
    },
    "investments-offer/index": {
      "cs": "/nabidka-investic",
      "en": "/investments-offer"
    },
    "investments-offer/[slug]": {
      "cs": "/nabidka-investic/[slug]",
      "en": "/investments-offer/[slug]"
    },
    "about": {
      "cs": "/o-nas",
      "en": "/about"
    },
    "annual-reports": {
      "cs": "/vyrocni-zpravy",
      "en": "/annual-reports"
    },
    "contact": {
      "cs": "/kontakt",
      "en": "/contact"
    },
    "contractual-documentation": {
      "cs": "/smluvni-dokumentace",
      "en": "/contractual-documentation"
    },
    "obligatory-published-information": {
      "cs": "/povinne-informace",
      "en": "/obligatory-published-information"
    },
    "forgotten-password": {
      "cs": "/zapomenute-heslo",
      "en": "/forgotten-password"
    },
    "how-does-it-work": {
      "cs": "/jak-to-funguje",
      "en": "/how-does-it-work"
    },
    "login": {
      "cs": "/prihlasit-se",
      "en": "/login"
    },
    "privacy-settings": {
      "cs": "/nastaveni-soukromi",
      "en": "/privacy-settings"
    },
    "registration": {
      "cs": "/registrace",
      "en": "/registration"
    },
    "security": {
      "cs": "/bezpecnost",
      "en": "/security"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "language": "cs-CZ",
    "isCatchallLocale": true,
    "files": [
      {
        "path": "C:/FE02/_work/254/s/Gallery/locales/cs.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
