import validate from "C:/FE02/_work/254/s/Gallery/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45router_45ready_45global from "C:/FE02/_work/254/s/Gallery/middleware/auth-router-ready.global.ts";
import manifest_45route_45rule from "C:/FE02/_work/254/s/Gallery/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45router_45ready_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "advertising-agreement-client": () => import("C:/FE02/_work/254/s/Gallery/middleware/advertising-agreement.client.ts"),
  "after-login-client": () => import("C:/FE02/_work/254/s/Gallery/middleware/after-login.client.ts"),
  "amendment-client": () => import("C:/FE02/_work/254/s/Gallery/middleware/amendment.client.ts"),
  "authenticated-client": () => import("C:/FE02/_work/254/s/Gallery/middleware/authenticated.client.ts"),
  "in-progress-client-client": () => import("C:/FE02/_work/254/s/Gallery/middleware/in-progress-client.client.ts")
}